<template>
  <div>
    <section class="page-title text-center mb-5">
      <div class="container">
        <div class="content-box">
          <h1>
            {{ $t("myreservations") }}
          </h1>
        </div>
      </div>
    </section>
    <div style="background-color: white; padding-top: 36px; padding-bottom: 150px">
      <div class="container">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col" style="width:100px">#</th>
              <th scope="col">{{ $t('First Name') }}</th>
              <th scope="col">{{ $t('Last Name') }}</th>
              <th scope="col">{{ $t('Payment Method') }}</th>
              <th scope="col">{{ $t('Massage') }}</th>
              <th scope="col">{{ $t('Plan') }}</th>
              <th scope="col">{{ $t('Day') }}</th>
              <th scope="col">{{ $t('Time') }}</th>
              <th scope="col">{{ $t('Masser') }}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item of list">
              <th scope="row">{{ $codePadding(item.id) }}</th>
              <td>{{ item.fname }}</td>
              <td>{{ item.lname }}</td>
              <td>
                {{ item.paymentMethod }}
                -
                {{ item.usersId.paymentDone ? "And Done" : "But Not Complite" }}
              </td>
              <td>{{ $getByLang(item.massageId.name) }}</td>

              <td>


                {{ $getByLang(item.pricesId.name) }} ({{ item.pricesId.price }} €)
              </td>

              <td>{{ $durationFormat(item.start) }}</td>
              <td>{{ $durationFormatStartToEnd(item.start, item.end) }}</td>
              <td>{{ item.adminsId.fullName }}</td>

              <td class="text-center">
                <router-link :to="'onereservations/' + item.id">
                  <i class="fa fa-eye"></i>
                </router-link>
                <!-- <router-link
                  v-else
                  :to="
                    '/PaymentGift/' +
                      (Number(item.price) +
                        Number(item.recieverType == 'post' ? 3 : 0))
                  "
                >
                  <i class="fa fa-credit-card"></i>
                </router-link> -->
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [],
    };
  },

  methods: {},
  async created() {
    const user = JSON.parse(localStorage.userKB);

    this.$http
      .post("appointments/myappointments?usersId=" + user.id, {})
      .then((res) => {
        this.list = res.data.data;
      });
  },
};
</script>

<style></style>
